
import { computed, defineComponent, nextTick, PropType, ref, watchEffect } from 'vue';

interface ColorsItem {
  color: string;
  name: string;
}

export default defineComponent({
  props: {
    colors: {
      type: Array as PropType<ColorsItem[]>,
      default: () => [
        { color: '#fff', name: '默认' },
        { color: '#ff2452', name: '红色' },
        { color: '#ff8542', name: '橙色' },
        { color: '#089b32', name: '绿色' },
        { color: '#428fff', name: '蓝色' },
        { color: '#c942ff', name: '紫色' },
        { color: '#fb6cb1', name: '粉色' },
        { color: '#333333', name: '黑色' },
        { color: '#fbd540', name: '黄色' },
        { color: '#fe9a04', name: '橙黄色' },
        { color: '#fa039c', name: '洋红色' },
        { color: '#6b473c', name: '浅褐色' },
        { color: '#843900', name: '褐色' },
        { color: '#666400', name: '棕绿色' },
        { color: '#b2d235', name: '浅绿色' },
        { color: '#029834', name: '深绿色' },
        { color: '#2c6dff', name: '浅蓝色' },
        { color: '#d27fff', name: '浅紫色' },
        { color: '#b600ff', name: '深紫色' },
        { color: '#009ad6', name: '青色' },
        { color: '#00ae9d', name: '青绿色' },
        { color: '#102b6a', name: '青蓝色' },
      ],
    },
    currentValue: {
      type: String as PropType<string>,
    },
  },
  setup(props, { emit }) {
    const selectVal = ref();

    watchEffect(() => {
      selectVal.value = props.currentValue
        ? props.colors.find(item => item.color === props.currentValue)
        : props.colors[0];
    });

    const selectFocus = ref(false);
    const selectBarStyle = computed(() => {
      return {
        color: selectVal.value ? '#333' : '#aaa',
        borderColor: selectFocus.value ? '#666798' : '#dcdfe6',
      };
    });
    // 设置 select 框是否获取焦点
    const setFocus = () => {
      selectFocus.value = !selectFocus.value;
    };

    // 选择事件
    const onSelect = item => {
      const prev = { ...selectVal.value };
      emit('beforeChange', item);

      selectVal.value = item;
      selectFocus.value = false;

      emit('change', item, prev);
    };

    // 全局绑定click事件，控制下拉框失去焦点
    const setGlobalBlur = () => {
      document.addEventListener('click', function () {
        selectFocus.value = false;
      });
    };

    const selectWidth = ref(0);
    const selectWrap = ref();
    // 设置 select 框的width
    const setSelectWidth = () => {
      nextTick(() => {
        selectWidth.value = selectWrap.value.offsetWidth - 24;
      });
    };

    return {
      selectVal,
      selectFocus,
      selectBarStyle,
      setFocus,
      setGlobalBlur,
      selectWidth,
      setSelectWidth,
      selectWrap,
      onSelect,
    };
  },
  mounted() {
    this.setGlobalBlur();
    this.setSelectWidth();
  },
});

<template>
  <div class="mail-setting">
    <header>
      <m-page-header title="邮件设置" />
    </header>
    <main>
      <div class="mail-control-wrap">
        <div class="mail-img-wrap">
          <div class="mail-bg"></div>
        </div>
        <div class="mail-control">
          <h5 class="control-title">礼品卡邮件</h5>
          <div class="control">
            <el-space wrap size="large">
              <a
                target="_blank"
                :href="`${api}/giftcard-mail-edit.html?plugin_token=${state.token}`"
              >
                <el-button type="primary" size="medium">可视化编辑</el-button>
              </a>
              <a
                target="_blank"
                :href="`${api}/giftcard-mail-tpl.html?plugin_token=${state.token}`"
              >
                <el-button size="medium">
                  <i class="giftcard-iconfont icon-newform-preview"></i>
                  预览
                </el-button>
              </a>
            </el-space>
          </div>
        </div>
      </div>
      <!-- 表单 -->
      <div class="form-wrap">
        <set-mail-form />
      </div>
    </main>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import MPageHeader from '@/components/pageHeader/PageHeader';
import SetMailForm from '../components/setMailForm/SetMailForm';
import { api } from '../../../tools/config';
export default {
  components: { MPageHeader, SetMailForm },
  setup() {
    const { state } = useStore();
    return {
      api: api(),
      state,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../statics/scss/iconfont';
@import '@/assets/scss/tool';
main {
  padding: 50px 30px;
  padding-bottom: 0;
  box-sizing: border-box;
  .mail-control-wrap {
    display: flex;

    .mail-img-wrap {
      flex: 0 0 514px;
      padding: 12px;
      border: solid 1px #d7dde3;
      margin-right: 94px;

      .mail-bg {
        background-image: url(../statics/img/mailPreview.png);
        background-size: cover;
        width: 516px;
        height: 306px;
        background-repeat: no-repeat;
      }
    }
    .mail-control {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .control-title {
        font-weight: bold;
      }
      & > div {
        margin: 26px 0;

        &.control {
          margin: 4px 0;
          margin-top: 16px;
          .el-button {
            min-width: 136px;
          }
        }
        &.control-info {
          color: $infoColor;
        }
      }

      h4 {
        color: #555;
        font-weight: 500;
      }
    }
  }
  .form-wrap {
    padding-top: 30px;
  }
}
</style>

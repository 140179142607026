import request from '@/tools/request';

export const saveData = (data, loading) => {
  return request({
    url: '/giftcard-mail-emailset.html',
    method: 'post',
    loading,
    data,
  });
};
export const getMailDetail = (data, loading) => {
  return request({
    url: '/giftcard-mail-set.html',
    method: 'post',
    loading,
    data,
  });
};
